export const firstType = ["Показан", "Показано", "Показано"];
export const secondType = ["Выбран", "Выбрано", "Выбрано"];
export const thirdType = ["Всего", "Всего", "Всего"];
export const firstAction = ["контейнер", "контейнера", "контейнеров"];
export const secondAction = ["позиция", "позиции", "позиций"];
export const thirdAction = ["день", "дня", "дней"];

export const dateFormat = "DD-MM-YYYY";

export const sortOrders = [
  {
    value: "ASC",
    label: "По возрастанию",
  },
  {
    value: "DESC",
    label: "По убыванию",
  },
];

export const sortList = [
  {
    value: "number",
    label: "Номеру контейнера",
  },
  {
    value: "companyName",
    label: "Названию компании",
  },
  // {
  //   value: "dateOfArrival",
  //   label: "Дате прибытия",
  // },
  {
    value: "weight",
    label: "Весу контейнера",
  },
  {
    value: "volume",
    label: "Объему контейнера",
  },
  {
    value: "boxCount",
    label: "Количеству коробок",
  },
  {
    value: "packCount",
    label: "Количеству штук",
  },
  {
    value: "daysToPaidStorage",
    label: "Количеству дней до платного хранения",
  },
  {
    value: "overdueCost",
    label: "Сумме простоя",
  },
  {
    value: "statusContainers",
    label: "Статусу контейнера",
  },
];

export const statusContainersOrders = [
  {
    value: "created",
    label: "Контейнер создан",
  },
  {
    value: "send",
    label: "Отправлен на утверждение времени",
  },
  {
    value: "accepted",
    label: "Время разгрузки подтверждено",
  },
  {
    value: "delete",
    label: "Контейнер удален",
  },
  {
    value: "update",
    label: "Контейнер обновлен",
  },
];

export const statusContainers = {
  created: {
    label: "Контейнер создан",
    color: "warning",
    key: "created",
  },
  send: {
    label: "Отправлен на утверждение времени",
    color: "volcano",
    key: "send",
  },
  accepted: {
    label: "Время разгрузки подтверждено",
    color: "success",
    key: "accepted",
  },
  delete: {
    label: "Контейнер удален",
    color: "error",
    key: "delete",
  },
  update: {
    label: "Контейнер обновлен",
    color: "cyan",
    key: "update",
  },
  canceled: {
    label: "Контейнер отменен",
    color: "purple",
    key: "canceled",
  },
};
